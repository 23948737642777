<template>
    <div v-if="companyName" class="max-w-container mx-8 px-4 text-center">
        <span v-if="canChange">
            <button type="button"
                    class="header-company_change"
                    @click="showModal = true">
                <span class="block max-w-max mr-2 no-underline text-eco-gray-900">{{ companyName }}</span>
                <span class="block button-link button-link-green font-normal weglot-translate">
                    change<PublicIcon icon="change" width="16" height="16" class="ml-1 fill-current inline-block" />
                </span>
            </button>
        </span>
        <div v-else class="w-full ml-2 text-center font-medium text-eco-gray-900">{{ companyName }}</div>

        <UserModal v-if="showModal" top-tab="EcoClaim" @closed="closed">
            <label :for="id" class="block h3 weglot-translate">Select a Company</label>
            <!-- use the company multiselect directly as we don't need the errors or other filtering & options -->
            <FieldCompanyMultiselect :id="id"
                                     v-model="selectedCompanyId"
                                     v-focus="true"
                                     :companies="companies"
                                     :show-company-types="false"
                                     :loading="state.matches('loading')"
                                     @search-changed="searchChanged" />

            <LoadingSpinner v-if="state.matches('changing')" class="mt-4">Changing company…</LoadingSpinner>
        </UserModal>
    </div>
</template>

<script setup>
import { useRootStore } from '@/user/stores/root';
import { computed, onMounted, ref, watch } from 'vue';
import { createMachine } from 'xstate';
import { useMachine } from '@xstate/vue';
import { MyCompaniesQuery } from '@/user/queries/user.query.graphql';
import { useQuery } from '@vue/apollo-composable';
import { useRoute, useRouter } from 'vue-router';
import { companyTypes } from '@/common/company_types';
import FieldCompanyMultiselect from '@/common/field_company_multiselect.vue';
import cuid from 'cuid';
import { switchCurrentCompany } from '@/common/lib';
import { translate } from '@/common/translate';

const rootStore = useRootStore();
const router = useRouter();
const route = useRoute();

const stateMachine = createMachine({
    id: 'component',
    initial: 'loading',
    states: {
        loading: {
            on: {
                LOADED: 'loaded',
                ERROR: 'error',
            },
        },
        loaded: {
            on: {
                CHANGE: 'changing',
                RESET: 'loading',
            },
        },
        changing: {
            on: {
                CHANGED: 'changed',
                RESET: 'loading',
                ERROR: 'error',
            },
        },
        changed: {
            on: {
                RESET: 'loading',
            },
        },
        error: {
            on: {
                RESET: 'loading',
            },
        },
    },
});
const { snapshot: state, send: sendEvent } = useMachine(stateMachine);

const showModal = ref(false);
const selectedCompanyId = ref(null);
const companies = ref([]);
const filter = ref(null);
const notAllowedRoutes = [
    'project-add',
    'project-edit',
    'project-company-add',
    'project-company-edit',
    'trip-add',
    'trip-edit',
];
const showCountryName = ref(true);
const id = cuid();
let allCompaniesString = ref('All Companies');

onMounted(async () => {
    allCompaniesString.value = await translate(allCompaniesString.value);
});

const companyName = computed(() => {
    if (!rootStore.currentCompany) {
        return null;
    }

    if ('ALL' === rootStore.currentCompany) {
        return allCompaniesString.value;
    }

    return rootStore.currentCompany.companyName;
});
const canChange = computed(() => {
    return rootStore.hasRole('ROLE_ADMIN') || rootStore.user.companies.length > 1;
});

watch(showModal, (value) => {
    if (value && notAllowedRoutes.includes(route.name)) {
        const msg = 'Changing the company will result in loosing your current data.';
        if (confirm(msg)) {
            router.push({ name: 'projects' });
        } else {
            showModal.value = false;
        }
    }
});

watch(showModal, (value) => {
    if (value) {
        filter.value = null;
        loadCompanies();
    }
});

watch(filter, () => {
    loadCompanies();
});

watch(selectedCompanyId, async (value) => {
    if (value) {
        sendEvent({ type: 'CHANGE' });

        await switchCurrentCompany(value);

        if ('403' === route.name) {
            router.push({ name: 'projects' });
        }

        setTimeout(() => {
            sendEvent({ type: 'CHANGED' });
            closed();
        }, 1000);
    }
});

const loadCompanies = () => {
    const { onResult, onError } = useQuery(
        MyCompaniesQuery,
        { filters: { q: filter.value } },
        { debounce: 250 },
    );
    onResult(async ({ data: { MyCompanies: Companies } }) => {
        companies.value = [];
        if (!filter.value) {
            companies.value.push({ companyId: 'ALL', companyName: await translate('– All Companies –') });
        }

        // only check if we should show the country if it hasn't already been set to true
        // if it's set to true, then we show the country until they close the company select
        if (!showCountryName.value) {
            const countries = new Set();
            Companies.reduce(
                (countries, company) => countries.add(company.address.country.abbreviation),
                countries,
            );
            if (countries.size > 1) {
                showCountryName.value = true;
            }
        }

        companies.value.push(...Companies.map((c) => ({
            ...c,
            addressString: `${c.address.city}, ${c.address.province.abbreviation || c.address.province.name}`
                + (showCountryName.value ? `, ${c.address.country.name}` : ''),
            companyTypeNames: c.companyTypes.map((ct) => companyTypes[ct]).join(', '),
        })));

        sendEvent({ type: 'LOADED' });
    });
    onError(() => {
        sendEvent({ type: 'ERROR' });
    });
};

const searchChanged = (search) => {
    filter.value = search;
};

const closed = () => {
    showModal.value = false;
    selectedCompanyId.value = null;
    sendEvent({ type: 'RESET' });
};
</script>
