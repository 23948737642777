import Voter from '@/common/classes/voter';
import { useRootStore } from '@/user/stores/root';
import { getCompanyFeatureFlag } from '@/common/company_feature_flags';

export function menuItems () {
    const rootStore = useRootStore();
    const voter = new Voter();

    return [
        {
            name: 'Claims/Projects',
            route: { name: 'projects' },
            // unique key for the v-for loop
            key: 'projects',
            // the route names when this menu item should be shown as active
            activeRoutes: [
                'projects',
                'project-view',
                'project-add',
                'project-edit',
                'trip-add',
                'trip-edit',
                'job-add',
                'job-edit',
            ],
            icon: 'shield',
            visibleOnlyIfCanModify: false,
        },
        {
            name: 'Contents',
            // oauth link that redirects to the Green Choice
            // eslint-disable-next-line max-len
            href: `/oauth/authorize?response_type=code&client_id=${encodeURIComponent(import.meta.env.VITE_GREEN_CHOICE_OAUTH_CLIENT_ID)}&redirect_uri=${encodeURIComponent(import.meta.env.VITE_GREEN_CHOICE_OAUTH_REDIRECT_URI)}&scope=CONTENTS`,
            key: 'contents',
            icon: 'shield',
            visibleOnlyIfCanModify: false,
            companyFeatureFlag: 'CONTENTS',
        },
        {
            name: 'Energy',
            route: { name: 'energy' },
            key: 'energy',
            icon: 'energy',
            visibleOnlyIfCanModify: false,
        },
        {
            name: 'Shop/Yard',
            route: { name: 'transfer-stations' },
            key: 'shop-yard',
            icon: 'transfer',
            visibleOnlyIfCanModify: true,
        },
        {
            name: 'Dashboards',
            route: { name: 'reports' },
            key: 'dashboards',
            activeRoutes: [ 'reports', 'report' ],
            icon: 'reports',
            visibleOnlyIfCanModify: false,
        },
        {
            name: 'Admin',
            route: { name: 'admin' },
            key: 'admin',
            activeRoutes: [
                'admin',
                'building',
                'building-add',
                'building-edit',
                'vehicle',
                'vehicle-add',
                'vehicle-edit',
            ],
            icon: 'gear',
            visibleOnlyIfCanModify: true,
            userCompanyRole: 'ROLE_COMPANY_ADMIN',
        },
    ].filter((item) => {
        return 'ALL' === rootStore.currentCompany
            || (
                rootStore.currentCompany
                && (!item.visibleOnlyIfCanModify || voter.canCompanyModify())
                && (undefined === item.userCompanyRole || voter.hasUserCompanyRole(item.userCompanyRole))
                && (
                    undefined === item.companyFeatureFlag
                    || getCompanyFeatureFlag(rootStore.currentCompany.featureFlags, item.companyFeatureFlag)
                )
            );
    });
}
